<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <template #toolbar>
        <dsb-press-sheets-templates-sync
          v-if="hadAdminRole"
          @sync:success="$_refreshData"
        />
      </template>

      <vbt-table
        :headers="headers"
        :items="pressSheetsTemplates"
        show-details-action
        @click:details="goToPressSheetDetails"
      >
        <template #name="{ item }">
          <dsb-link :link="item.sourceUrl">
            {{ item.name }}
          </dsb-link>
        </template>

        <template #machines="{ item }">
          <v-layout column>
            <span
              v-for="(str, i) in item.machines"
              :key="i"
            >
              {{ str }}
            </span>
          </v-layout>
        </template>

        <template #pressSheetTypes="{ item }">
          <v-layout column>
            <span
              v-for="(str, i) in item.pressSheetTypes"
              :key="i"
            >
              {{ str }}
            </span>
          </v-layout>
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
        :total-count="pressSheetsTemplatesTotalCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import {
  AuthNamespace,
  AutocompleteNamespace,
  AutocompleteActionTypes,
  AppLocationsNamespace,
} from '@store/types';

import {
  namespace as PressSheetsTemplatesNamespace,
  ActionTypes as PressSheetsTemplatesActionTypes,
} from '../store.types';

import DsbPressSheetsTemplatesSync from '../_components/DsbPressSheetsTemplatesSync.vue';

const dataLoadingOptions = {
  getterName: PressSheetsTemplatesActionTypes.GET_PRESS_SHEETS_TEMPLATES,
  contentName: 'pressSheetsTemplates',
};
const paginationOptions = { contentName: 'pressSheetsTemplates' };
const searchOptions = {
  initialSearchDataFields: [
    'name',
  ],
};

const headers = Object.freeze([
  { text: 'Outer ID ', value: 'outerId' },
  { text: 'Name', value: 'name' },
  { text: 'Machines', value: 'machines' },
  { text: 'Press Sheets Types', value: 'pressSheetTypes' },
  { text: 'Sides Up', value: 'maxSidesUp' },
  { text: 'Version', value: 'version' },
]);

export default {
  name: 'DsbPressSheetsTemplates',

  components: {
    DsbPressSheetsTemplatesSync,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapState(AutocompleteNamespace, ['pressSheetsTemplatesAutocomplete']),

    ...mapState(PressSheetsTemplatesNamespace, [
      'pressSheetsTemplates',
      'pressSheetsTemplatesTotalCount',
    ]),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),

    searchFieldsConfig() {
      return [
        {
          value: 'name',
          label: 'Name',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.pressSheetsTemplatesAutocomplete,
          callback: this.getPressSheetsTemplatesAutocomplete,
          flex: 'sm4',
        },
      ];
    },
  },

  methods: {
    ...mapActions(AutocompleteNamespace, {
      getPressSheetsTemplatesAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE,
        { query },
      ),
    }),

    ...mapActions(PressSheetsTemplatesNamespace, [
      PressSheetsTemplatesActionTypes.GET_PRESS_SHEETS_TEMPLATES,
    ]),

    goToPressSheetDetails({ id }) {
      this.$router.push({
        name: 'profitPressSheetsTemplate',
        params: { id },
        query: { suffix: this.suffix },
      });
    },
  },
};
</script>
